.App {
  main {
    margin-top: 1rem; 
  }
}

.card {
  margin-bottom: 1rem;

  &:last-of-type {
    margin-bottom: 0;
  }
}